import React from 'react'
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import { css } from 'glamor';
import { useTranslation } from 'react-i18next';
import getLang from '../../utils/getLang';
import { useEffect } from 'react';
import { Observer, GLOBAL_EVENT } from '../../Observer';

const btn = css({
    background: 'none',
    outline: 'none',
    border: 'none',
    ':active': {
        outline: 'none',
        border: 'none',
    },
    ':hover': {
        outline: 'none',
        border: 'none',
    },
    ':focus': {
        outline: 'none',
        border: 'none',
    }
});

const active_lang = css({
    border: '2px solid var(--secondary-color)',
    boxSizing: 'content-box'
});

const LangsFlags = () => {
    const { i18n } = useTranslation();
    const data = useStaticQuery(graphql`
        query {
            flag_es: file(absolutePath: { regex: "/flags/flag_es.png/"}) {
                childImageSharp {
                    fixed(width: 32, height: 22) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            flag_en: file(absolutePath: { regex: "/flags/flag_en.png/"}) {
                childImageSharp {
                    fixed(width: 32, height: 22) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `);

    // Buscamos y seleccionamos un idioma
    // 1) Se busca automaticamente con el navegador
    // 2) Se compara con el localStorage y luego se asigna.
    useEffect(() => {
        Observer.on(GLOBAL_EVENT.SET_LANG, () => {
            const navigator_lang = getLang();
            const user_lang = localStorage.getItem('lang');

            const lang = (user_lang !== null) ? user_lang : navigator_lang;
            if (lang !== i18n.language) {
                handleChangeLang(lang);
            }
        });
        return () => {
            Observer.removeListener(GLOBAL_EVENT.INDEX_POSTS_LOADED);
        };
    });

    const handleChangeLang = (lang) => {
        i18n.changeLanguage(lang);
        localStorage.setItem('lang', lang);
    }

    const handleKeyDown = (ev) => {

    }

    return (
        <div>
            <button className={`mx-0 mx-md-1 cursor-pointer ${btn}`} onClick={() => handleChangeLang('es')} onKeyPress={handleKeyDown}>
                <Image fixed={data.flag_es.childImageSharp.fixed} className={`${(i18n.language === 'es') ? active_lang : ''} align-middle`} />
            </button>
            <button className={`mx-0 mx-md-1 mr-0 mr-sm-3 cursor-pointer ${btn}`} onClick={() => handleChangeLang('en')} onKeyPress={handleKeyDown}>
                <Image fixed={data.flag_en.childImageSharp.fixed} className={`${(i18n.language === 'en') ? active_lang : ''} align-middle`} />
            </button>
        </div>
    );
}

export default LangsFlags;