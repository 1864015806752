import React from 'react';
import './Footer.scss';
import { useStaticQuery, graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

const Footer = () => {
    const {t} = useTranslation();
    const data = useStaticQuery(graphql`
    query {
        site {
          siteMetadata {
            author
          }
        }
      }
    `);
    return (
        <footer className="mt-4">
            <div className="container">
                <div className="row d-flex align-self-stretch">
                    <div className="col-12 footer-text text-center">
                        <address>
                            © {new Date().getFullYear()}, {data.site.siteMetadata.author} |
                        {/* <Link to="/">Condiciones de usos</Link> |  <Link to="/">Privacidad</Link> |  */}
                            &nbsp;{t('footer', {returnObjects: true}).contact}: <a href="mailto:mailgammafp@gmail.com" className="anchor-page">gammafp@gmail.com</a>
                        </address>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;