import React, { useRef } from 'react';
import Image from 'gatsby-image';
import { useStaticQuery, graphql, Link } from 'gatsby';

import './LogoGammix.scss';
import { useEffect } from 'react';
import { useState } from 'react';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { useTranslation } from 'react-i18next';

const LogoGammix = ({location}) => {
    const data = useStaticQuery(graphql`
        query {
            gammix_rodolfo: file(absolutePath: { regex: "/gammix_rodolfo.png/"}) {
                childImageSharp {
                    fixed(width: 134, height: 134) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `);
    const gammix_say = useRef(null);
    const gammix_position = useRef();
    const [gammix_talk, set_gammix_talk] = useState('');
    const [downSayGammix, setDownSayGammix] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        set_gammix_talk(t('bot', {returnObjects: true}).hello);
    }, [t]);

    useScrollPosition(({ prevPos, currPos }) => {
        const isShow = Math.abs(currPos.y) > 200;
        if (isShow) {
            setDownSayGammix(true);
        } else {
            setDownSayGammix(false);
        }
    }, [downSayGammix], false, false, 300);

    return (
        <span ref={gammix_position} className="logo-gammix-container">
            <div className="logo_gammix">
                <Link to="/">
                    <Image fixed={data.gammix_rodolfo.childImageSharp.fixed} />
                </Link>
            </div>
            {
                (gammix_talk !== '') &&
                <div className="gammix-say" ref={gammix_say} style={(downSayGammix) ? { top: '43px', zIndex: -2, left: '180px', paddingLeft: '20px' } : {}}>
                    {gammix_talk}
                </div>
            }

        </span>
    )
}
export default LogoGammix;