import React from 'react';
import './Header.scss';
import { useStaticQuery, graphql } from 'gatsby';
import LangsFlags from '../LangsFlags/LangsFlags';
import { useTranslation } from 'react-i18next';
import { YoutubeSVG, TwitterSVG } from '../../utils/IconsSVG';

export const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          author
          description
          social {
            twitter
          }
        }
      }
    }
  `);
  const { t } = useTranslation();
  return (
    <header className="header-bio">
      <div className="lang-selector text-center text-sm-right p-2">
        <LangsFlags />
      </div>
      <div className="header-bio--container">
        <div className="logo">{data.site.siteMetadata.title}</div>
        <div className="description pl-1 pr-1 pl-md-0 pr-md-0">
          <p>
            {t('description')}
            {/* {data.site.siteMetadata.description} */}
          </p>
          <div className="social mb-1">
            <a href="https://www.youtube.com/user/GammaFp1" target="_blank" rel="noopener noreferrer">
              <YoutubeSVG width="30" fill="#fff" />
            </a>
            <a href="https://twitter.com/gammafp" className="mx-1" target="_blank" rel="noopener noreferrer">
              <TwitterSVG width="30" fill="#fff" />
            </a>
          </div>
        </div>
      </div>
    </header>
  )
}
