import React from 'react';
import blog_image from '../../vendor/blog_image.png';
import './Card.scss';
import { Link } from 'gatsby';
import { tags_colors } from '../../Observer';

const Cards = ({ tag = 'Tag', title = 'Title', cover = null, excerpt = 'excerpt', className = '', slug = '', link = '' }) => {
    const excerpt_cut = (excerpt.length > 179) ? excerpt.substring(0, 180) + '...' : excerpt;
    const cover_image = (cover !== null) ? cover : blog_image;
    const card_color = (tags_colors[tag] !== undefined) ? tags_colors[tag] : 'orange';
    return (
        <React.Fragment>
            {
                (slug !== '')
                    ?
                    <div className={`card-container ${className}`}>
                        <div className={`card-image-tag`}>
                            <Link to={slug}>
                                <img src={cover_image} alt={title} className={`w-100 card-image`} />
                            </Link>
                            <Link to={`/tag/${tag}`} className={`card-tag text-capitalize`} style={{ backgroundColor: card_color }}>{tag}</Link>
                        </div>
                        <Link to={slug}>
                            <div className={`card-title text-left`} style={{ backgroundColor: card_color }}>
                                {title}
                            </div>
                            <div className="card-body text-left">
                                {excerpt_cut}
                            </div>
                            <div className="text-center">
                                <div className="border-bottom-card" style={{ borderBottom: `3px solid ${card_color}` }}> </div>
                            </div>
                        </Link>
                    </div>
                    :
                    <div className={`card-container ${className}`}>
                        <div className={`card-image-tag`}>
                            <a href={link}>
                                <img src={cover_image} alt={title} className={`w-100 card-image`} />
                            </a>
                            <Link href={`/tag/${tag}`} className={`card-tag text-capitalize`} style={{ backgroundColor: card_color }}>{tag}</Link>
                        </div>
                        <a href={link}>
                            <div className={`card-title text-left`} style={{ backgroundColor: card_color }}>
                                {title}
                            </div>
                            <div className="card-body text-left">
                                {excerpt_cut}
                            </div>
                            <div className="text-center">
                                <div className="border-bottom-card" style={{ borderBottom: `3px solid ${card_color}` }}> </div>
                            </div>
                        </a>
                    </div>
            }
        </React.Fragment>

    )
}

export default Cards;