import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { useEffect } from 'react';
import { Pushbar } from '../../vendor/pushbar.js/pushbar';
import { navigate } from '@reach/router';

import './Menu.scss';
import '../../vendor/pushbar.js/pushbar.css';
import { css } from 'glamor';
import { useTranslation } from 'react-i18next';
import Image from 'gatsby-image';

const menu_active = css({
    textDecoration: 'underline !important'
});

export const Menu = ({ location }) => {

    const data = useStaticQuery(graphql`
    query {
        patreon: file(absolutePath: { regex: "/become_patreon.png/"}) {
            childImageSharp {
                fixed(width: 130, height: 40) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        kofi: file(absolutePath: { regex: "/kofi.png/"}) {
            childImageSharp {
                fixed(width: 130, height: 40) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
    }
`);
console.log( "·dato: ", data);

    const { i18n } = useTranslation();
    const menu_items = [
        { Blog: '/blog' },
        { Projects: `/${i18n.language}/projects` },
        { Tools: '/tools' }
    ];
    useEffect(() => {
        let pushbar = null;
        if (pushbar === null) {
            pushbar = new Pushbar({
                blur: true,
                overlay: true,
            });
        }
        return () => {
            pushbar.close();
        }
    });
    return (
        <React.Fragment>
            <nav className="menu d-none d-sm-flex">
                <div className="logo ml-4">
                    <Link to="/">
                        GAMMAFP
                    </Link>
                </div>
                <ul>
                    {
                        menu_items.map(item => {
                            const key = Object.keys(item)[0];
                            return (
                                <li key={key}>
                                    <Link to={item[key]} className={((location.pathname.startsWith(item[key])) ? menu_active : '') + ' text-capitalize'}>
                                        {key}
                                    </Link>
                                </li>
                            );
                        })
                    }
                    <li>
                        <a href="https://www.patreon.com/gammafp">
                            <Image fixed={data.patreon.childImageSharp.fixed} />
                        </a>
                    </li>
                    <li>
                        <a href="https://ko-fi.com/gammafp">
                            <Image fixed={data.kofi.childImageSharp.fixed} />
                        </a>
                    </li>
                </ul>
            </nav>
            <div className="cellphone-menu-button d-sm-none">
                <span className="material-icons open" data-pushbar-target="cellphone-menu">menu</span>
            </div>
            <nav data-pushbar-id="cellphone-menu" data-pushbar-direction="left" className="cellphone-menu d-sm-none">
                <div className="logo-and-close">
                    <div className="logo">
                        <button data-pushbar-close onClick={() => {
                            navigate('/');
                        }}>
                            GAMMAFP
                        </button>
                    </div>
                    <span data-pushbar-close className="material-icons close-button">close</span>
                </div>
                <div className="cellphone-menu--items">
                    <button className="p-0 m-0">
                        <a href="https://www.patreon.com/gammafp" className="p-0 m-0">
                            <Image fixed={data.patreon.childImageSharp.fixed} className="w-100 p-0 m-0"/>
                        </a>
                    </button>
                    <button className="p-0 m-0">
                        <a href="https://ko-fi.com/gammafp" className="p-0 m-0">
                            <Image fixed={data.kofi.childImageSharp.fixed} className="w-100 p-0 m-0"/>
                        </a>
                    </button>
                    {
                        menu_items.map(item => {
                            const key = Object.keys(item)[0];
                            return (
                                <button key={key} className="noselect text-capitalize" onClick={() => {
                                    navigate(item[key]);
                                }}>{key}</button>
                            );
                        })
                    }
                </div>
            </nav>
        </React.Fragment>
    )
}
