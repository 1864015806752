import EventEmitter from 'eventemitter3';

let Observer = new EventEmitter();

let GLOBAL_EVENT = {
   MENU_DOM_LOADED: 'MENU_DOM_LOADED',
   // fix para el cambio de idioma, una vez cargados los posts se puede cambiar el idioma
   SET_LANG: 'SET_LANG'
};
const tags_colors = {
    phaser: 'red',
    develop: 'orange',
    uno: 'blue',
    tool: '#34495e',
    project: '#3498db'
}
export {
    Observer,
    GLOBAL_EVENT,
    tags_colors
};