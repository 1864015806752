const getLang = () => {
    const navigator_lang =  navigator.language.split('-')[0]
    let final_lang = 'en';
    if(navigator_lang === 'gl') {
        final_lang = 'gl';
    } else {
        final_lang = (["ca", "es", "eu"].indexOf(navigator_lang) !== -1) ?
            "es" :
            "en";
    }
    return final_lang;

}

export default getLang;