import React from "react";
import { Menu } from "./Menu/Menu";
import Footer from "./Footer/Footer";
import '../i18n';
import { css } from "glamor";
import LogoGammix from "./LogoGammix/LogoGammix";

const main_height = css({
  minHeight: 'calc(100vh - 117px)'
});

const Layout = ({ location, title, children }) => {
  
  return (
    <React.Fragment>
      <Menu location={location}/>

      <main className={`${main_height}`}>
        {children}
      </main>

      <Footer />
      <LogoGammix location={location}/>
    </React.Fragment>
  )
}

export default Layout
